
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Main } from 'layouts';
import Container from 'components/Container';
import Hero from './Hero';
import p3 from 'assests/Photos/i5-a/i5-1.jpg';

const IFiveAcademy = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
    <Container  maxWidth={0.78}  paddingTop={'0 !important'}>
        <Grid>
            <br />
            <br />
        </Grid>
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column-reverse'}>
      <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
          sx={{
            display: { xs: '2', md: 'flex' },
          }}
        >
          <Box component={Card} boxShadow={4} height={1} width={1}>
            <Box
              component={CardMedia}
              height={1}
              width={1}
              minHeight={300}
              image={p3}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={4}>
          <Box marginTop={{ xs: 2, sm: 4 }} >
          {/* <Box
              component={CardMedia}
              height={0.5}
              width={.5}
              minHeight={100}
              minWidth={100}
              image="https://i.imgur.com/FXiCSzS.png"
               /> */}
              <Typography variant={'h4'} sx={{ fontWeight: 700 }}
              align={'justify'}> 
            
               I-5 Academy
              </Typography>
              <Typography color="text.secondary" component={'p'}>
              INDIGENOUS   INNOVATIVE   INTELLECTUAL   INQUISITIVE   INDEPENDENT <br/><br/>
              </Typography>
              <Typography color="text.secondary" align={'justify'}></Typography>
            </Box>
            <Typography component='p' align={'justify'} color="text.secondary">
            I-5 Acadamy is the Montessori wing at National Public School, Yeshwanthpur, Bangalore.
<br></br>
<br></br>


            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
    <Grid maxWidth={0.88} alignContent = "center">
      <br /><br />
  
      <Typography component='p' align={'justify'} color="text.secondary" variant='h5'>
        <br/> Our vision<br />
      </Typography>
      <Typography component='p' align={'justify'} color="text.secondary" >
      To be a leading global institution in the provision of quality education programmes. To nurture and foster in young children disciplined habits and prepare them to be lifelong learners.<br /><br />
      </Typography>
      <Typography component='p' align={'justify'} color="text.secondary" variant='h5'>
         Our Mission<br />
      </Typography>
      <Typography component='p' align={'justify'} color="text.secondary" >
      We look forward to foster learning by providing innovative, educational and experiential programmes, towards the development of dynamic individuals with strong moral values and a passion for lifelong learning.<br /><br />
      </Typography>
      <Typography component='p' align={'justify'} color="text.secondary" variant='h5'>
      Our Core Values<br />
      </Typography>
      <Typography component='p' align={'justify'} color="text.secondary" >
      Children First : We place the interests of the children to awaken joy in their creative expression.<br/>
Collaborative Partnership : We foster a strong relationship with parents and associates.<br/>
Culture of Excellence : We strive for excellence in holistic development of each child.<br/>
Professional Conduct : We hold the highest standard of integrity and treat everybody with respect and dignity.<br/>
Community Spirit : We inculcate a strong sense of unity along with community spirit in our students.<br/><br />
      </Typography>
      <Typography component='p' align={'justify'} color="text.secondary" variant='h5'>
         Our Philosophy<br />
      </Typography>
      <Typography component='p' align={'justify'} color="text.secondary" >
Learning is a joyful journey that begins from birth and continues throughout our lives culminating in a lifelong pursuit of knowledge and wisdom.<br /><br />
</Typography>
<Typography component='p' align={'justify'} color="text.secondary" variant='h5'>
         Our Curriculum<br />
         
      </Typography>
      <Typography component='p' align={'justify'} color="text.secondary" >
      Our indigenous curriculum fosters a supportive and responsive environment for the holistic development of children with commitment, love and respect.<br />
We believe that every child is a unique, competent and capable individual, with innovative ideas, unique talents, and unlimited possibilities for change and growth that must be nurtured, encouraged and developed to his or her fullest potential.<br />
We believe that early childhood education should foster holistic development, to serve as the foundation for intellectual growth and development.<br />
We believe that early childhood education should nurture life skills, and promote inquisitiveness in children to make them lifelong learners who have positive dispositions, attitudes and feelings towards learning.<br />
We recognize and support the areas of development in children by promoting meaningful and collaborative partnership and making them independent.<br />
</Typography>
    </Grid>
    </Container>
    {/* <Container maxWidth={0.9}  paddingTop={'0 !important'}>
      <Hero />
    </Container> */}
    </Main>
  );
};

export default IFiveAcademy;
